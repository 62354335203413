require('./bootstrap');
import { Fancybox } from "@fancyapps/ui/src/Fancybox/Fancybox.js";
import "@fancyapps/ui/dist/fancybox.css";

window.Cookies = require("js-cookie");

window.moment = require('moment');
moment.locale('ru');

import "daterangepicker/daterangepicker.js";
import "daterangepicker/daterangepicker.css";

